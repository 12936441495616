.your-iith-email2 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.text-input-xmx-child {
  height: 3.75rem;
  width: 22.31rem;
  position: relative;
  border-radius: var(--br-lg);
  background-color: var(--color-gainsboro-200);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.xxmtech11001iithacin {
  height: 1.81rem;
  position: relative;
  font-size: var(--font-size-9xl);
  font-family: var(--font-inconsolata);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  z-index: 1;
}
.text-input-xmx {
  cursor: pointer;
  border: 1px solid var(--color-white);
  padding: var(--padding-xs) var(--padding-2xl) var(--padding-lgi)
    var(--padding-base);
  background-color: var(--color-gainsboro-200);
  border-radius: var(--br-lg);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.text-input-xmx:hover {
  background-color: var(--color-silver);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
}
.frame-name-aam,
.frame-name-bbm {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-name-bbm {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-lg);
}
.frame-name-aam {
  height: 10.25rem;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-13xl) 0 var(--padding-6xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--color-white);
  font-family: var(--font-josefin-sans);
}
@media screen and (max-width: 825px) {
  .your-iith-email2 {
    font-size: var(--font-size-19xl);
  }
}
@media screen and (max-width: 450px) {
  .your-iith-email2 {
    font-size: var(--font-size-10xl);
  }
  .xxmtech11001iithacin {
    font-size: var(--font-size-3xl);
  }
}
