.how-it-works {
  margin: 0;
  height: 4.25rem;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
}
.text-input-frame {
  width: 36.25rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.you-will-fill,
.your-crushes-list {
  margin: 0;
  position: relative;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
}
.you-will-fill {
  height: 3.75rem;
  font-size: var(--font-size-41xl);
}
.your-crushes-list {
  font-size: inherit;
  max-width: 100%;
}
.line-separator-child {
  height: 3.75rem;
  width: 22.31rem;
  position: relative;
  border-radius: var(--br-lg);
  background-color: var(--color-gainsboro-200);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.aabtech11001iithacin {
  height: 1.81rem;
  position: relative;
  font-size: var(--font-size-9xl);
  font-family: var(--font-inconsolata);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  z-index: 1;
}
.line-separator {
  cursor: pointer;
  border: 1px solid var(--color-white);
  padding: var(--padding-xs) var(--padding-2xl) var(--padding-lgi)
    var(--padding-base);
  background-color: var(--color-gainsboro-200);
  border-radius: var(--br-lg);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.email-input:hover,
.line-separator-item,
.line-separator1:hover,
.line-separator2:hover,
.line-separator:hover,
.rectangle-group:hover {
  background-color: var(--color-silver);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
}
.line-separator-item {
  height: 3.75rem;
  width: 22.31rem;
  position: relative;
  border-radius: var(--br-lg);
  background-color: var(--color-gainsboro-200);
  border: 1px solid var(--color-white);
  display: none;
  max-width: 100%;
}
.aamtech11002iithacin {
  height: 1.81rem;
  position: relative;
  font-size: var(--font-size-9xl);
  font-family: var(--font-inconsolata);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  z-index: 1;
}
.line-separator-inner,
.line-separator1 {
  border-radius: var(--br-lg);
  background-color: var(--color-gainsboro-200);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  max-width: 100%;
}
.line-separator1 {
  cursor: pointer;
  padding: var(--padding-xs) var(--padding-2xl) var(--padding-lgi)
    var(--padding-base);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.line-separator-inner {
  height: 3.75rem;
  width: 22.31rem;
  position: relative;
  display: none;
}
.bbmtech11003iithacin {
  height: 1.81rem;
  position: relative;
  font-size: var(--font-size-9xl);
  font-family: var(--font-inconsolata);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  z-index: 1;
}
.arrow-connect,
.line-separator2 {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.line-separator2 {
  cursor: pointer;
  border: 1px solid var(--color-white);
  padding: var(--padding-xs) var(--padding-2xl) var(--padding-lgi)
    var(--padding-base);
  background-color: var(--color-gainsboro-200);
  border-radius: var(--br-lg);
  flex-direction: row;
  justify-content: center;
}
.arrow-connect {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs) 0 0;
  gap: var(--gap-lg);
}
.frame-column-aab-aam-bbm {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-2xl);
}
.frame-column-aab-aam-bbm,
.text-input-crush,
.you-will-fill-this-parent {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.text-input-crush {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-13xl) 0 var(--padding-4xl);
  box-sizing: border-box;
}
.you-will-fill-this-parent {
  width: 25.88rem;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-38xl);
  min-width: 25.88rem;
}
.your-crush-will {
  height: 3.75rem;
  display: inline-block;
}
.your-crush-will,
.your-crushs-crushes {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.ggm-techiithacin-child {
  height: 19.13rem;
  width: 30.69rem;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 153px);
  left: -26.12rem;
  object-fit: contain;
  z-index: 1;
}
.cylinder-with-arrows-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
}
.cylero-3-icon {
  position: absolute;
  top: 5.19rem;
  left: 15.31rem;
  width: 5.75rem;
  height: 6rem;
  object-fit: cover;
  z-index: 3;
}
.cylinder-with-arrows {
  height: 14.19rem;
  width: 30.81rem;
  position: absolute;
  margin: 0 !important;
  top: -8.19rem;
  left: -26.19rem;
}
.ggm-techiithacin {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.frame-child7 {
  height: 3.75rem;
  width: 22.31rem;
  position: relative;
  border-radius: var(--br-lg);
  background-color: var(--color-gainsboro-200);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.ggmtech11003iithacin {
  position: relative;
  font-size: var(--font-size-9xl);
  font-family: var(--font-inconsolata);
  color: var(--color-white);
  text-align: center;
  white-space: nowrap;
  z-index: 1;
}
.rectangle-group {
  cursor: pointer;
  border: 1px solid var(--color-white);
  padding: var(--padding-xs) var(--padding-xl) var(--padding-lgi)
    var(--padding-base);
  background-color: var(--color-gainsboro-200);
  border-radius: var(--br-lg);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.ggm-techiithacin-parent {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-lg);
}
.frame-your-input,
.ggm-techiithacin-parent,
.xxm-techiithacinffb-techiithac {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.xxm-techiithacinffb-techiithac {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-24xl) 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--font-size-29xl);
}
.frame-your-input {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-38xl);
  min-width: 37.25rem;
  font-size: var(--font-size-41xl);
}
.frame-parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.email-input-child {
  height: 3.75rem;
  width: 22.31rem;
  position: relative;
  border-radius: var(--br-lg);
  background-color: var(--color-gainsboro-200);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.ccmtech11004iithacin {
  height: 1.81rem;
  position: relative;
  font-size: var(--font-size-9xl);
  font-family: var(--font-inconsolata);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  z-index: 1;
}
.email-input {
  cursor: pointer;
  border: 1px solid var(--color-white);
  padding: var(--padding-xs) var(--padding-2xl) var(--padding-lgi)
    var(--padding-base);
  background-color: var(--color-gainsboro-200);
  border-radius: var(--br-lg);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.p,
.p1,
.p2,
.p3,
.p4 {
  margin: 0;
}
.blank-frame1 {
  height: 9.06rem;
  position: relative;
  display: inline-block;
}
.empty-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xs);
  max-width: calc(100% - 34px);
}
.p5,
.p6,
.p7,
.p8,
.p9 {
  margin: 0;
}
.arrow-line {
  height: 9.06rem;
  position: relative;
  display: inline-block;
}
.blank-frame {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.blank-frame-wrapper,
.group-frame,
.text-input-list {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.blank-frame-wrapper {
  width: 67.94rem;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-4xl);
  box-sizing: border-box;
  font-size: var(--font-size-9xl);
  font-family: var(--font-inconsolata);
}
.group-frame,
.text-input-list {
  flex-direction: column;
}
.text-input-list {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-29xl);
}
.group-frame {
  width: 85.13rem;
  align-items: center;
  gap: var(--gap-60xl);
}
.list-frame {
  height: 0.19rem;
  width: 90rem;
  position: relative;
  border-top: 3px solid var(--color-orangered);
  box-sizing: border-box;
  max-width: calc(100% - 0px);
}
.gift-box-frame {
  align-self: stretch;
  height: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 100%;
}
.sub-cylinder-frame,
.valentine-frame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.sub-cylinder-frame {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-1167xl);
}
.valentine-frame {
  width: 98.31rem;
  flex-direction: row;
  padding: 0 var(--padding-9xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-49xl);
  color: var(--color-white);
  font-family: var(--font-josefin-sans);
}
@media screen and (max-width: 1425px) {
  .sub-cylinder-frame {
    gap: var(--gap-1167xl);
  }
}
@media screen and (max-width: 1200px) {
  .frame-your-input,
  .you-will-fill-this-parent {
    flex: 1;
  }
  .frame-parent5 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 825px) {
  .how-it-works {
    font-size: var(--font-size-35xl);
  }
  .you-will-fill {
    font-size: var(--font-size-29xl);
  }
  .your-crushes-list {
    font-size: var(--font-size-19xl);
  }
  .you-will-fill-this-parent {
    min-width: 100%;
  }
  .your-crush-will {
    font-size: var(--font-size-29xl);
  }
  .your-crushs-crushes {
    font-size: var(--font-size-19xl);
  }
  .xxm-techiithacinffb-techiithac {
    padding-right: var(--padding-2xl);
    box-sizing: border-box;
  }
  .frame-your-input {
    gap: var(--gap-38xl);
    min-width: 100%;
  }
  .group-frame {
    gap: var(--gap-60xl);
  }
  .sub-cylinder-frame {
    gap: var(--gap-1167xl);
  }
}
@media screen and (max-width: 450px) {
  .how-it-works {
    font-size: var(--font-size-22xl);
  }
  .you-will-fill {
    font-size: var(--font-size-17xl);
  }
  .your-crushes-list {
    font-size: var(--font-size-10xl);
  }
  .aabtech11001iithacin,
  .aamtech11002iithacin,
  .bbmtech11003iithacin {
    font-size: var(--font-size-3xl);
  }
  .you-will-fill-this-parent {
    gap: var(--gap-38xl);
  }
  .your-crush-will {
    font-size: var(--font-size-17xl);
  }
  .your-crushs-crushes {
    font-size: var(--font-size-10xl);
  }
  .arrow-line,
  .blank-frame1,
  .ccmtech11004iithacin,
  .ggmtech11003iithacin {
    font-size: var(--font-size-3xl);
  }
  .group-frame {
    gap: var(--gap-60xl);
  }
  .sub-cylinder-frame {
    gap: var(--gap-1167xl);
  }
}
