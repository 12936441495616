.cruh-crushes-list-arrow-child {
  height: 3.75rem;
  width: 22.31rem;
  position: relative;
  border-radius: var(--br-lg);
  background-color: var(--color-gainsboro-200);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.xxmtech11001iithacin1 {
  height: 1.81rem;
  position: relative;
  font-size: var(--font-size-9xl);
  font-family: var(--font-inconsolata);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  z-index: 1;
}
.cruh-crushes-list-arrow,
.cruh-crushes-list-arrow-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.cruh-crushes-list-arrow {
  cursor: pointer;
  border: 1px solid var(--color-white);
  padding: var(--padding-xs) var(--padding-2xl) var(--padding-lgi)
    var(--padding-base);
  background-color: var(--color-gainsboro-200);
  border-radius: var(--br-lg);
  justify-content: center;
}
.cruh-crushes-list-arrow:hover {
  background-color: var(--color-silver);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
}
.cruh-crushes-list-arrow-wrapper {
  justify-content: flex-start;
  padding: 0 var(--padding-9xs) 0 0;
}
@media screen and (max-width: 450px) {
  .xxmtech11001iithacin1 {
    font-size: var(--font-size-3xl);
  }
}
