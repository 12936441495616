.first-ellipse {
  top: 42.19rem;
  left: 99.63rem;
  background: linear-gradient(#c347ac, #c347ac), #c347ac;
  width: 8.38rem;
  height: 19.63rem;
}
.first-ellipse,
.first-ellipse1,
.second-ellipse {
  position: absolute;
  border-radius: 50%;
  filter: blur(500px);
}
.first-ellipse1 {
  top: 54.88rem;
  left: 19.25rem;
  background: linear-gradient(#8842a1, #8842a1), #c347ac;
  width: 19.63rem;
  height: 19.63rem;
}
.second-ellipse {
  top: 0;
  left: 84.88rem;
  background: linear-gradient(#3a2f74, #3a2f74), #c347ac;
  width: 23.13rem;
  height: 27.13rem;
}
.image-1-icon3 {
  position: absolute;
  top: 9.38rem;
  left: 46.19rem;
  border-radius: 0 var(--br-xl) var(--br-xl) 0;
  width: 52.44rem;
  height: 51.06rem;
  object-fit: cover;
  z-index: 2;
}
.email-text-frames-child {
  width: 40.19rem;
  height: 51.06rem;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-lavender);
  display: none;
}
.frame-child1,
.your-iith-email1 {
  position: relative;
  z-index: 2;
}
.frame-child1 {
  width: 24.31rem;
  height: 0.19rem;
}
.second-crush-child {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}
.crush-2-email1 {
  width: 15rem;
  border: 0;
  outline: 0;
  font-family: var(--font-josefin-sans);
  font-size: var(--font-size-xl);
  background-color: transparent;
  height: 1.25rem;
  position: relative;
  color: var(--color-darkgray);
  text-align: left;
  display: inline-block;
  z-index: 2;
}
.second-crush {
  align-self: stretch;
  height: 3.13rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.frame-child2 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}
.second-crush-parent {
  align-self: stretch;
  height: 5.56rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-20xl);
}
.crush-3-email1 {
  position: relative;
  z-index: 2;
}
.frame-parent1 {
  width: 24.31rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.frame-child3 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.crush-4-email1,
.frame-child3 {
  position: relative;
  z-index: 2;
}
.vector-group {
  width: 24.31rem;
  height: 3.13rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.frame-child4 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.crush-5-email1,
.frame-child4 {
  position: relative;
  z-index: 2;
}
.vector-container {
  width: 24.31rem;
  height: 3.13rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.frame-child5 {
  width: 24.31rem;
  height: 0.19rem;
  position: relative;
  z-index: 2;
}
.third-email-input-parent,
.vector-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.third-email-input-parent {
  gap: var(--gap-20xl);
}
.vector-parent {
  padding: 0 0 0.94rem;
  gap: 2.69rem;
}
.rectangle-div {
  height: 2.38rem;
  width: 7.69rem;
  position: relative;
  border-radius: var(--br-smi);
  background-color: var(--color-white);
  box-shadow: -2px -3px 4px rgba(182, 66, 245, 0.47) inset;
  display: none;
}
.submit {
  position: relative;
  font-size: var(--font-size-xl);
  font-family: var(--font-josefin-sans);
  color: var(--color-black);
  text-align: left;
  z-index: 1;
}
.rectangle-parent {
  cursor: pointer;
  border: 0;
  padding: var(--padding-4xs) var(--padding-xs) var(--padding-4xs)
    var(--padding-base);
  background-color: var(--color-white);
  border-radius: var(--br-smi);
  box-shadow: -2px -3px 4px rgba(182, 66, 245, 0.47) inset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.email-text-frames {
  position: absolute;
  top: 9.38rem;
  left: 9.38rem;
  border-radius: var(--br-xl);
  background-color: var(--color-lavender);
  width: 40.19rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-63xl) var(--padding-77xl) 4.38rem var(--padding-55xl);
  box-sizing: border-box;
  gap: var(--gap-20xl);
  z-index: 1;
}
.macbook-pro-16-31 {
  width: 100%;
  height: 69.81rem;
  position: relative;
  background-color: var(--color-black);
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-darkgray);
  font-family: var(--font-josefin-sans);
}
