@import url("https://fonts.googleapis.com/css2?family=Handjet:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inconsolata: Inconsolata;
  --font-josefin-sans: "Josefin Sans";
  --font-handjet: Handjet;

  /* font sizes */
  --font-size-9xl: 1.75rem;
  --font-size-3xl: 1.38rem;
  --font-size-29xl: 3rem;
  --font-size-19xl: 2.38rem;
  --font-size-10xl: 1.81rem;
  --font-size-41xl: 3.75rem;
  --font-size-17xl: 2.25rem;
  --font-size-49xl: 4.25rem;
  --font-size-35xl: 3.38rem;
  --font-size-22xl: 2.56rem;
  --font-size-77xl: 6rem;
  --font-size-109xl: 8rem;
  --font-size-32xl: 3.19rem;
  --font-size-13xl: 2rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.31rem;

  /* Colors */
  --color-black: #000;
  --color-orangered: #ef441d;
  --color-white: #fff;
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro-200: rgba(217, 217, 217, 0);
  --color-silver: rgba(191, 191, 191, 0);
  --color-lavender: #eddafd;
  --color-darkgray: #9d9d9d;

  /* Gaps */
  --gap-403xl: 26.38rem;
  --gap-1167xl: 74.13rem;
  --gap-60xl: 4.94rem;
  --gap-base: 1rem;
  --gap-xl: 1.25rem;
  --gap-2xs: 0.69rem;
  --gap-38xl: 3.56rem;
  --gap-lg: 1.13rem;
  --gap-2xl: 1.31rem;
  --gap-56xl: 4.69rem;
  --gap-3xs: 0.63rem;
  --gap-11xl: 1.88rem;
  --gap-6xs: 0.44rem;
  --gap-35xl: 3.38rem;
  --gap-16xl: 2.19rem;
  --gap-20xl: 2.44rem;

  /* Paddings */
  --padding-9xl: 1.75rem;
  --padding-4xl: 1.44rem;
  --padding-xs: 0.75rem;
  --padding-2xl: 1.31rem;
  --padding-lgi: 1.19rem;
  --padding-base: 1rem;
  --padding-24xl: 2.69rem;
  --padding-xl: 1.25rem;
  --padding-7xs: 0.38rem;
  --padding-9xs: 0.25rem;
  --padding-6xl: 1.56rem;
  --padding-13xl: 2rem;
  --padding-11xs: 0.13rem;
  --padding-54xl: 4.56rem;
  --padding-17xl: 2.25rem;
  --padding-189xl: 13rem;
  --padding-116xl: 8.44rem;
  --padding-69xl: 5.5rem;
  --padding-119xl: 8.63rem;
  --padding-2xs: 0.69rem;
  --padding-71xl: 5.63rem;
  --padding-39xl: 3.63rem;
  --padding-63xl: 5.13rem;
  --padding-77xl: 6rem;
  --padding-55xl: 4.63rem;
  --padding-4xs: 0.56rem;

  /* Border radiuses */
  --br-lg: 18px;
  --br-xl: 20px;
  --br-smi: 13px;
}
