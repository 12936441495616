.main-frame {
  width: 40.88rem;
  height: 43.31rem;
  position: absolute;
  margin: 0 !important;
  top: -16.19rem;
  right: -17.75rem;
  border-radius: 50%;
  background: linear-gradient(#3a2f74, #3a2f74), #c347ac;
  filter: blur(500px);
}


.centered-div {
  /* width: 100%; Ensure the div takes up the full width */
  display: flex;
  justify-content: center; /* Horizontal alignment */
  align-items: center; /* Vertical alignment */
}


.empty-continer
{
  display: block;
}


.exclusive-button {
  padding: 10px 20px;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: bold;
  text-align: center; /* Center the text */
  width: auto; /* Allow the button to expand based on its content */
}

.exclusive-button:hover {
  background-color: #cc0000;
}


.cylero-2-icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.192);
}
.wrapper-cylero-2 {
  height: 65.5rem;
  width: 65.13rem;
  position: absolute;
  margin: 0 !important;
  bottom: -27.31rem;
  left: calc(50% - 521px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}



.we-got-you,
.wrapper-cylero-2-parent {
  flex: 1;
  position: relative;
  max-width: 100%;
}
.we-got-you {
  margin: 0;
  height: 12.69rem;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  text-shadow: 6px 12px 18.8px #000;
  z-index: 1;
}
.wrapper-cylero-2-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.macbook-pro-16-2,
.macbook-pro-16-2-inner {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.macbook-pro-16-2-inner {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-54xl);
  min-height: 16rem;
  max-width: 100%;
  text-align: center;
  font-size: 9.63rem;
  color: var(--color-white);
  font-family: var(--font-josefin-sans);
}
.macbook-pro-16-2 {
  width: 100%;
  position: relative;
  background-color: var(--color-black);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  padding: 8.81rem 0 100.81rem;
  gap: var(--gap-403xl);
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .macbook-pro-16-2-inner {
    padding-left: var(--padding-17xl);
    padding-right: var(--padding-17xl);
    box-sizing: border-box;
  }
  .macbook-pro-16-2 {
    gap: var(--gap-403xl);
  }
}
@media screen and (max-width: 825px) {
  .we-got-you {
    font-size: 3.88rem;
  }
}
@media screen and (max-width: 450px) {
  .we-got-you {
    font-size: var(--font-size-19xl);
  }
  .macbook-pro-16-2 {
    gap: var(--gap-403xl);
  }
}
