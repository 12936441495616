.cylero-1-icon {
  width: 17.75rem;
  height: 17.75rem;
  position: relative;
  object-fit: cover;
}
.heart,
.purple {
  margin: 0;
}
.purple-heart {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.cylero-1-parent,
.frame-wrapper1 {
  display: flex;
  justify-content: flex-start;
}
.cylero-1-parent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-3xs);
}
.frame-wrapper1 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 var(--padding-13xl);
}
.ellipse-div {
  width: 20.88rem;
  height: 19.63rem;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(#8842a1, #8842a1), #c347ac;
  filter: blur(500px);
  max-width: 100%;
}
.frame-parent3,
.frame-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent3 {
  align-self: stretch;
  gap: var(--gap-56xl);
}
.frame-wrapper {
  width: 22.31rem;
  padding: var(--padding-119xl) var(--padding-2xs) 0 0;
  box-sizing: border-box;
}
.wrapper-group-1-child {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  left: 0.19rem;
  top: 0;
  transform: scale(Infinity);
}
.wrapper-group-1 {
  height: 52.13rem;
  width: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wanna-celebrate-this {
  margin: 0;
  align-self: stretch;
  height: 30.19rem;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}
.wanna-celebrate-this-valentine-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 28.25rem;
  min-height: 40rem;
  max-width: 100%;
}
.frame-child6 {
  height: 19.63rem;
  width: 19.63rem;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(#c347ac, #c347ac), #c347ac;
  filter: blur(500px);
  min-width: 19.63rem;
}
.frame-parent4,
.frame-wrapper2 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent4 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  row-gap: 20px;
}
.frame-wrapper2 {
  width: 63.06rem;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-189xl) 0 0;
  box-sizing: border-box;
  font-size: var(--font-size-77xl);
  font-family: var(--font-josefin-sans);
}
.frame-parent2,
.macbook-pro-16-2-child {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.frame-parent2 {
  width: 100rem;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 113%;
  flex-shrink: 0;
}
.macbook-pro-16-2-child {
  width: 90rem;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-109xl);
  color: var(--color-white);
  font-family: var(--font-handjet);
}
@media screen and (max-width: 1425px) {
  .frame-parent2 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1200px) {
  .frame-wrapper {
    padding-top: var(--padding-71xl);
    box-sizing: border-box;
  }
  .wanna-celebrate-this-valentine-wrapper {
    min-height: auto;
  }
  .frame-child6 {
    flex: 1;
  }
  .frame-parent4 {
    flex-wrap: wrap;
  }
  .frame-wrapper2 {
    padding-top: var(--padding-116xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .purple-heart {
    font-size: var(--font-size-32xl);
  }
  .frame-wrapper {
    padding-top: var(--padding-39xl);
    box-sizing: border-box;
  }
  .wanna-celebrate-this {
    font-size: var(--font-size-29xl);
  }
  .wanna-celebrate-this-valentine-wrapper {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .purple-heart {
    font-size: var(--font-size-13xl);
  }
  .frame-parent3 {
    gap: var(--gap-56xl);
  }
  .wanna-celebrate-this {
    font-size: var(--font-size-10xl);
  }
  .frame-wrapper2 {
    padding-top: var(--padding-69xl);
    box-sizing: border-box;
  }
}
